'use client';

import { signIn, useSession } from 'next-auth/react';
import { redirect, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
export default function SignInPage() {
  const {
    status,
    data
  } = useSession();
  const searchParams = useSearchParams();
  useEffect(() => {
    if (status === 'unauthenticated') {
      signIn('cognito');
    } else if (status === 'authenticated') {
      redirect(searchParams.get('callbackUrl') ?? '');
    }
  }, [status, data, searchParams]);
  return null;
}